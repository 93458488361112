// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Con = styled.div`
  height: 100%;
  @media (max-width: 992px) {
    height: auto;
  }
`;

export const RedDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: rgb(253, 62, 96);
  margin: 0 8px;
`;
export const TextCon = styled.span(
  ({ direction }) => `
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  margin-top:60px;
  background:#fff;
  align-items: ${direction};
  width: 100%;
  height: 100%;
  @media (max-width: 992px) {
    margin-top: 30px;
    align-items: center;
  }
    `
);

export const HeaderText = styled.span(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "DM Sans"};
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 100px;
  text-align: right;
  letter-spacing: -1.87579px;
  color: #1e2432;
  direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 58px;
    text-align: center;
    width: 100%;
  }
    `
);

export const Subtext = styled.span(
  ({ fontWeight, arabic }) => `
  font-family: ${arabic ? "Cairo" : "DM Sans"};
  font-style: normal;
  font-weight: ${fontWeight};
  font-size: 25px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align:  right;
  color: #191c1f;
  direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    width: 100%;
}
    `
);

// export const MobileImage = styled.div`
//   height: 375.11px;
//   width: 363px;
//   background-position: center;
//   background-size: cover;
//   background: url("https://storage.googleapis.com/uapply/Screen%20Shot%202022-12-19%20at%202.18%201.png");
// `;
