import React from "react";

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "118"}
      height={props.height ? props.height : "32"}
      fill="none"
      viewBox="0 0 118 32"
    >
      <path
        fill="#299CF7"
        d="M30.565 31.015c.97 0 2.11-.21 3.249-.629v-4.023c-.59.168-1.14.252-1.604.252-.928 0-1.477-.377-1.477-1.3V1.389H25.67V25.65c0 3.688 2.067 5.364 4.895 5.364zM55.349 19.994c0-5.741-3.123-10.476-9.58-10.476-6.92 0-9.874 5.447-9.874 10.853 0 5.532 3.038 10.644 10.212 10.644 3.503 0 6.245-1.215 8.524-3.73l-3.502-3.059c-1.435 1.635-3.123 2.221-4.98 2.221-3.291 0-4.98-1.802-5.19-4.525h14.263c.084-.671.127-1.3.127-1.928zM40.917 17.94c.295-2.054 1.73-4.233 4.81-4.233 2.659 0 4.684 1.592 4.769 4.232h-9.58zM67.567 9.518c-3.797 0-6.878 1.466-9.368 4.441l3.672 3.06c1.519-2.012 3.502-3.101 5.612-3.101 2.405 0 3.924 1.383 3.924 3.645v.587h-5.19c-2.363 0-4.135.293-5.528.922-2.026.922-3.123 2.766-3.123 5.196 0 3.94 3.123 6.747 7.512 6.747 2.742 0 5.106-1.173 6.33-3.101l.421 2.682h4.642V17.563c0-4.986-3.418-8.045-8.904-8.045zM65.88 26.74c-2.025 0-3.249-.922-3.249-2.389 0-.754.295-1.34.844-1.718.675-.503 1.688-.712 3.418-.712h4.515c0 2.681-2.49 4.819-5.528 4.819zM92.478 9.518c-2.363 0-4.684 1.382-5.57 3.939v-3.52h-5.106v20.659h5.063V20.203c0-3.897 1.52-5.782 4.6-5.782 1.013 0 1.815.25 2.532.712l2.448-4.442c-1.35-.838-2.574-1.173-3.967-1.173zM110.379 9.518c-2.532 0-4.811.838-6.288 2.807V9.937h-5.064v20.659h5.064V22.34c0-3.688 0-8.423 4.726-8.423 3.84 0 3.84 3.184 3.84 4.61v12.068h5.064V17.27c0-4.903-2.658-7.752-7.342-7.752z"
      ></path>
      <path
        fill="#FD3E60"
        d="M16.718 5.156l.093.093c.961.954 2.52.954 3.48 0a2.431 2.431 0 000-3.456l-.093-.092a2.473 2.473 0 00-3.48 0 2.432 2.432 0 000 3.455z"
      ></path>
      <path
        fill="#299CF7"
        stroke="#000"
        strokeWidth="0.002"
        d="M2.512.988l2.091 4.99H.416L2.512.987z"
      ></path>
      <path
        fill="#299CF7"
        d="M0 9.862l.018 10.813c0 2.871 1.006 5.305 3.017 7.303 2.037 1.997 4.526 2.996 7.467 2.996 2.967 0 5.456-.999 7.468-2.996 2.036-1.998 3.055-4.432 3.055-7.303l-.019-10.813h-5.015v10.813c0 1.598-.498 2.721-1.529 3.82-1.03 1.073-2.35 1.61-3.96 1.61-1.609 0-2.929-.537-3.96-1.61-1.005-1.099-1.526-2.197-1.526-3.82V9.862H0z"
      ></path>
    </svg>
  );
}

export default Logo;
