import React from "react";
import { Routes, Route } from "react-router-dom";
import { CareerTest } from "../Views/CareerTest";
import Home from "../Views/Home";
import MajorTest from "../Views/MajorTest";
import Result from "../Views/Result";
import { Apply } from "../Views/Apply";
import ResultUapply from "../Views/ResultUapply";
import { Success } from "../Views/Success";
import { Failed } from "../Views/Failed";
import { Exibition } from "../Views/Exibition";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route exact path="/apply/exhibition-2025" element={<Exibition />} />
      <Route path="/career" element={<CareerTest />} />
      <Route path="/major-test" element={<MajorTest />} />
      <Route path="/apply" element={<Apply />} />
      <Route path="/test-results" element={<Result />} />
      <Route path="/ar/success" element={<ResultUapply />} />
      <Route path="/kw/booking/success" element={<Success />} />
      <Route path="/booking/failed" element={<Failed />} />
    </Routes>
  );
}
