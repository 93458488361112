import React, { useState } from "react";
import styled from "styled-components";
import { DropdownComponent } from "../DropdownComponent/index";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  justify-content: space-between;
`;

// const Label = styled.label`
//   font-weight: bold;
//   margin-bottom: 8px;
// `;

// const Select = styled.select`
//   padding: 8px;
//   margin-bottom: 8px;
// `;

// const DatePickerWrapper = styled.div`
//   margin-bottom: 8px;
// `;
export default function DobComponent(props) {
  const { setSelectedDate } = props;
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonthValue, setselectedMonthValue] = useState("");

  const handleYearChange = (event) => {
    setSelectedYear(event);
  };

  const handleMonthChange = (event, value) => {
    const selectedMonthValue = event;
    setSelectedMonth(selectedMonthValue);
    setselectedMonthValue(value);
    setSelectedDay("");
    setSelectedDate(null);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event);
    const dateString = `${selectedYear}-${selectedMonthValue}-${event}`;
    const parts = dateString.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Months are zero-based in JavaScript
    const day = parseInt(parts[2]);

    const date = new Date(year, month, day);
    setSelectedDate(date);
  };

  const generateYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    let options = [];
    for (let year = currentYear; year >= startYear; year--) {
      options.push(year);
    }
    return options;
  };

  const generateMonthsOptions = () => {
    const options = [];
    for (let month = 1; month <= 12; month++) {
      options.push(
        new Date(0, month - 1).toLocaleString("en-us", { month: "long" })
      );
    }
    return options;
  };
  const generateDaysOptions = () => {
    if (selectedYear && selectedMonthValue) {
      const daysInMonth = new Date(
        Number(selectedYear),
        Number(selectedMonthValue),
        0
      ).getDate();
      const options = [];
      for (let i = 1; i <= daysInMonth; i++) {
        options.push(i);
      }

      return options;
    }
    return [];
  };

  return (
    <div>
      <Container className="d-flex flex-row-reverse">
        <div className="d-flex flex-column w-100">
          <DropdownComponent
            data={generateYearsOptions()}
            dob
            onClick={(e) => handleYearChange(e)}
            selected={selectedYear || "سنة"}
            ifSelected={selectedYear}
            style={{
              borderRadius: "200px",
              background: "rgba(217, 217, 217, 0.30)",
            }}
          />
        </div>
        <div style={{ margin: "0 10px" }} className="d-flex flex-column w-100">
          <DropdownComponent
            data={generateMonthsOptions()}
            dob
            ifSelected={selectedMonth}
            style={{
              borderRadius: "200px",
              background: "rgba(217, 217, 217, 0.30)",
            }}
            onClick={(e, key) => handleMonthChange(e, key)}
            selected={selectedMonth || "شهر"}
          />
        </div>
        <div className="d-flex flex-column w-100">
          <DropdownComponent
            data={generateDaysOptions()}
            dob
            ifSelected={selectedDay}
            style={{
              borderRadius: "200px",
              background: "rgba(217, 217, 217, 0.30)",
            }}
            onClick={(e) => handleDayChange(e)}
            selected={selectedDay || "تاريخ"}
          />
        </div>
      </Container>
    </div>
  );
}
