/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Con, TextCon, HeaderText, Subtext, RedDot } from "./styled/exhibition";
import { IndexIcon } from "../Assets/Images/IndexIcon";
import { ButtonComponent } from "../Component/ButtonComponent";
import { ModalComponent } from "../Component/Modal";
import { IndexIconEn } from "../Assets/Images/IndexIconEn";
import Logo from "../Assets/Images/Logo";
import { useLocation } from "react-router-dom";

export const Exibition = () => {
  const [show, setshow] = React.useState(false);

  React.useEffect(() => {
    // document.body.style.overflow = "hidden";
    // document.body.style.height = "100%";
  }, []);

  const arabic = true;

  const location = useLocation();
  console.log("first", location.pathname);
  return (
    <>
      <Con>
        {!arabic ? (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              right: 0,
              bottom: "-34px",
              // zIndex: 99,
            }}
          >
            <IndexIconEn />
          </div>
        ) : (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              left: 0,
              bottom: "-34px",
            }}
          >
            <IndexIcon />
          </div>
        )}

        <div
          style={{ zIndex: 99, position: "relative" }}
          className="mobile-view"
        >
          <div
            style={{
              width: "100%",
              zIndex: 99,
              padding: 15,
              position: "absolute",
              margin: "auto",
            }}
          >
            <Logo width={"145px"} height={"36.75px"} />
          </div>
          <img
            style={{
              position: "relative",
              //   bottom: 20,
              width: "80%",
              zIndex: 9,
            }}
            src={
              arabic
                ? "https://cdn.ulearn.study/Subject-removebg.png"
                : "https://cdn.ulearn.study/Subject-removebg.png"
            }
            alt="img"
          />
          <img
            alt="img"
            src="https://storage.googleapis.com/uapply/flag-bg.png"
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              height: "100%",
              left: 0,
            }}
          />
        </div>

        <div className="mobile-view">
          <TextCon
            direction={arabic ? "flex-end" : "flex-start"}
            className="container "
          >
            <HeaderText arabic={arabic}>
              أكبر معرض للجامعات البريطانية في الكويت 2025
            </HeaderText>
            {/* <HeaderText arabic={arabic}>{t("common:homeHeader2")}</HeaderText> */}

            <div style={{ marginTop: 20 }}>
              <Subtext fontWeight={900} arabic={arabic}>
                لغة <RedDot /> بكلوريوس <RedDot /> ماجستير <RedDot /> دكتوراة
              </Subtext>
            </div>

            <div className="mobile-view" style={{ width: "90%" }}>
              <ButtonComponent
                style={{
                  marginTop: 20,
                  padding: "0 58px",
                  width: "90%",
                  zIndex: 99,
                  marginBottom: 20,
                  borderRadius: 8,
                }}
                width="100%"
                className="res-home-btn "
                label={"ابدأ بالتسجيل"}
                loading={false}
                btnType={"dark"}
                onClick={() => setshow(true)}
              />
            </div>
          </TextCon>
        </div>

        <div className="pc-view">
          <TextCon
            direction={arabic ? "flex-end" : "flex-start"}
            className="container "
          >
            <HeaderText arabic={arabic}>
              أكبر معرض للجامعات
              <br />
              البريطانية في الكويت 2025
            </HeaderText>
            {/* <HeaderText arabic={arabic}>{t("common:homeHeader2")}</HeaderText> */}

            <div style={{ marginTop: 20 }}>
              <Subtext fontWeight={900} arabic={arabic}>
                لغة <RedDot /> بكلوريوس <RedDot /> ماجستير <RedDot /> دكتوراة
              </Subtext>
            </div>

            <div className="">
              <ButtonComponent
                style={{
                  marginTop: 20,
                  padding: "0 58px",
                  width: "max-content",
                  zIndex: 99,
                  marginBottom: 20,
                  borderRadius: 8,
                }}
                width="100%"
                className="res-home-btn "
                label={"ابدأ بالتسجيل"}
                loading={false}
                btnType={"dark"}
                onClick={() => setshow(true)}
              />
            </div>
          </TextCon>
        </div>
        <div
          className="mobile-view"
          style={{ zIndex: 99, position: "relative" }}
        >
          <Subtext
            fontWeight={900}
            arabic={arabic}
            style={{ marginBottom: 50, padding: "0 20px" }}
          >
            تاريخ 3 و 4 فبراير من الساعة 4م إلى ٩م في فندق راديسون بلو البدع،
            قاعة دسمان
          </Subtext>
          {/* <IndexIconMobile /> */}
          <div
            style={{
              position: "relative",
              width: "80%",
              margin: "0 auto",
              bottom: 25,
            }}
          >
            {/* iPad Frame */}
            <img
              style={{
                display: "block",
                width: "100%",
                position: "relative",
                zIndex: 1,
              }}
              src="https://storage.googleapis.com/uapply/Group%2034140%20(2).png"
              alt="iPad Frame"
            />

            {/* Inner Content (Second Image) */}
            <img
              style={{
                position: "absolute",
                top: "3.08%", // Adjust the percentages as needed to fit inside the frame
                left: "4%",
                width: "92%",
                height: "94%",
                borderRadius: 4,
                objectFit: "cover",
                zIndex: 2,
              }}
              src="https://cdn.ulearn.study/baner_img.png"
              alt="Content"
            />
          </div>

          <div>
            <img
              alt="img"
              src="https://storage.googleapis.com/uapply/blue-bg.png"
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            />
          </div>
        </div>
        {show ? (
          <ModalComponent show={show} onHide={() => setshow(false)} />
        ) : null}
      </Con>
    </>
  );
};
