import React from "react";
import { Dropdown } from "react-bootstrap";

export const DropdownComponent = (props) => {
  return (
    <div>
      <Dropdown className={`${props.ddClass} custom-dob-dd`}>
        <Dropdown.Toggle
          placeholder={props.placeholder}
          style={{
            // background: props.ifSelected ? "#323A46" : "#fff",
            // color: props.ifSelected ? "#fff" : "#191D23",
            opacity: props.ifSelected ? 1 : 0.5,
            ...props.style,
          }}
          variant="primary"
          id="dropdown-basic"
        >
          {props.title || props.selected}
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            maxHeight: 250,
            overflowY: "scroll",
            maxWidth: "max-content",
            left: 18,
            borderRadius: 10,
          }}
        >
          <div style={{ padding: "0 15px" }}>{props.element}</div>
          <Dropdown.Item disabled onSelect={() => {}}>
            Select an option
          </Dropdown.Item>

          {props.data.map((a, key) => (
            <Dropdown.Item
              style={{
                background: props.selected === a ? "#323A46" : "#fff",
                color: props.selected === a ? "#fff" : "#191D23",
              }}
              key={key}
              onClick={() => {
                props.onClick(a, key + 1);
              }}
            >
              {a}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
