import React, { useEffect, useRef, useState } from "react";
import { Container, Navbar, ProgressBar } from "react-bootstrap";
import Logo from "../Assets/Images/Logo";
import Countdown, { zeroPad } from "react-countdown";
import ClockIcon from "../Assets/Images/ClockIcon";
import * as questionData from "../Constant/questions";
import CompleteModal from "../Modal/CompleteModal";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router";

export default function MajorTest(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [timeTaken, settimeTaken] = useState("");
  const [results, setresults] = useState({});
  const [counter, setcounter] = useState(1);
  const [timeDiff, settimeDiff] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedQuestionArr, setselectedQuestionArr] = useState([]);
  const [questionObject, setquestionObject] = useState({
    Accounting: 12,
    Biology: 12,
    Chemistry: 12,
    "Chemical Engineering": 12,
    "Civil Engineering": 12,
    "Computer Science": 12,
    "Construction Management": 12,
    Economics: 12,
    "Electrical Engineering": 12,
    "English Literature": 12,
    "Environmental Science": 12,
    "Actuarial Science": 12,
    "Aeronautical Engineering": 12,
    "Architectural Engineering": 12,
    Architecture: 12,
    "Art & Design / Interior Design": 12,
    "Business Mangment": 12,
    "Communication - Journalism": 12,
    "Communication & Media Studies": 12,
    "Computer Engineering": 12,
    "Cyber Security": 12,
    "Data Science": 12,
    Dentistry: 12,
    "Electronic Engineering": 12,
    Entrepreneurship: 12,
    "Environmental Engineering": 12,
    Finance: 12,
    Geology: 12,
    "Graphic Design": 12,
    History: 12,
    "Hospitality Administration": 12,
    "Human Resources Management": 12,
    "Industrial Engineering": 12,
    "International Business": 12,
    "International Relations": 12,
    Linguistics: 12,
    "Management Information Systems": 12,
    Marketing: 12,
    "Mass Communications": 12,
    Mathematics: 12,
    Medicine: 12,
    Music: 12,
    Nursing: 12,
    Nutrition: 12,
    Pharmacy: 12,
    Physics: 12,
    Physiotherapy: 12,
    "Political Science": 12,
    Psychology: 12,
    Radiography: 12,
    "Renewable & Sustainable Energy Engineering": 12,
    Sociology: 12,
    Education: 12,
    Statistics: 12,
    "Theatre and Dance": 12,
    "Veterinary Medicine": 12,
    Law: 12,
    "Agricultural science": 12,
    "Mechanical Engineering": 12,
  });
  const clockRef = useRef();
  const time = 15000 * 60;
  const [date] = useState(Date.now() + time);
  const [timeEnd, settimeEnd] = useState(false);
  const [randomQuestions, setrandomQuestions] = useState([]);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  //   const router = useRouter();
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }
  useEffect(() => {
    document.getElementById("html").style.overflow = "hidden";
    setrandomQuestions(shuffle(questionData.questions));

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    const time = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    settimeTaken(time);

    if (completed) {
      settimeEnd(true);
      return timeEnd;
    }
    return (
      <div className="d-flex flex-row" style={{ paddingBottom: 4 }}>
        <span
          style={{
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 21,
            color: time > "00:05:00" ? "#191C1F" : "#FD3E60",
          }}
        >
          <ClockIcon /> {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
        <div style={{ paddingLeft: 8 }}>
          {/* <ClockIcon color={time > "00:05:00" ? "#191C1F" : "#FD3E60"}  /> */}
        </div>
      </div>
    );
  };
  const location = useLocation();
  // if (!location.state) {
  //   return <Navigate to="/" />;
  // }
  const handlePause = () => clockRef.current.pause();
  const query = location.search;

  return (
    <div>
      <Navbar
        style={{
          backgroundColor: "#fff",
          boxShadow:
            scrollPosition === 0 ? "none" : "inset 0px -1px 0px #E8E9F5",
          padding: 0,
          position: "sticky",
          flexDirection: "column",
          justifyContent: "center",
          top: 0,
        }}
        sticky="top"
      >
        <Container
          style={{ padding: "10px 20px" }}
          className=" d-flex justify-content-between w-100"
        >
          <a href="/">
            <Logo />
          </a>
          <div className="pc-view w-100">
            <div className="w-100 d-flex align-items-end justify-content-center">
              <ProgressBar
                className="career-progress"
                now={counter * 3.33333}
                label={""}
              />
            </div>
          </div>
          <div
            style={{
              width: 160,
            }}
            className="d-flex flex-column align-items-end justify-content-end"
          >
            {" "}
            <span className="time-text">
              <Countdown
                zeroPadTime
                date={date}
                renderer={renderer}
                ref={clockRef}
              />
            </span>
            <span className="remaining-time">الوقت المتبقي</span>
          </div>
        </Container>
        <div className="mobile-view w-100">
          {" "}
          <div className="w-100 d-flex align-items-end justify-content-center ">
            <ProgressBar
              style={{ borderRadius: 0, width: "100%" }}
              className="career-progress"
              now={counter * 3.33333}
              label={""}
            />
          </div>
        </div>
      </Navbar>

      <div
        // className="d-flex justify-content-center"
        style={{ height: "100vh" }}
      >
        <div style={{ paddingTop: 30 }}>
          {randomQuestions.map((a, key) => {
            if (key + 1 === counter) {
              return (
                <>
                  <div key={key} className="question-text">
                    {a.Question_ar}
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ padding: "37px 0 25px 0" }}
                  >
                    <div
                      className="question-img"
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${a.image})`,
                        borderRadius: 20,
                        //   backgroundPosition: "50%",
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <div
                      className="ans-btn d-flex align-items-center justify-content-center"
                      style={{
                        height: 52,
                        width: 126,
                        background: "#FFFFFF",
                        border: "1px solid #E8E9F5",
                        boxSizing: "border-box",
                        borderRadius: 5,
                        marginRight: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setquestionObject(a.answer);
                        var programs = [questionObject, a.answer];
                        function subtract(r1, r2) {
                          return Object.keys(r1).reduce((a, k) => {
                            a[k] = r1[k] - r2[k];
                            return a;
                          }, {});
                        }

                        let res = programs.reduce((a, c) => subtract(a, c));

                        if (key + 1 === counter) {
                          setquestionObject(res);
                        }

                        if (key + 1 === 30) {
                          setresults(res);
                          setModalShow(true);
                          selectedQuestionArr.push({
                            question: a.position - 1,
                            ans: "No",
                          });
                          handlePause();
                          var hms = timeTaken;
                          var data = hms.split(":");

                          var mili =
                            (+data[0] * 60 * 60 + +data[1] * 60 + +data[2]) *
                            1000;

                          var diff = Math.abs(time - mili);
                          settimeDiff(diff);
                        } else {
                          setcounter(counter + 1);
                          selectedQuestionArr.push({
                            question: a.position - 1,
                            ans: "No",
                          });
                        }
                      }}
                    >
                      {" "}
                      <span className="major-ans-text"> لا 👎</span>
                    </div>
                    <div
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #E8E9F5",
                        boxSizing: "border-box",
                        borderRadius: 5,
                        height: 52,
                        width: 126,
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center ans-btn justify-content-center"
                      onClick={() => {
                        setquestionObject(a.answer);
                        // const result = sumObjectsByKey();

                        const res = [questionObject, a.answer].reduce(
                          (a, { ...rest }) => {
                            Object.entries(rest).forEach(([key, val]) => {
                              a[key] = (a[key] || 0) + val;
                            });
                            return a;
                          },
                          {}
                        );

                        if (key + 1 === counter) {
                          setquestionObject(res);
                        }
                        if (key + 1 === 30) {
                          setresults(res);
                          setModalShow(true);
                          selectedQuestionArr.push({
                            question: a.position - 1,
                            ans: "Yes",
                          });
                          handlePause();
                          var hms = timeTaken;
                          var data = hms.split(":");

                          var mili =
                            (+data[0] * 60 * 60 + +data[1] * 60 + +data[2]) *
                            1000;

                          var diff = Math.abs(time - mili);
                          settimeDiff(diff);
                        } else {
                          setcounter(counter + 1);
                          selectedQuestionArr.push({
                            question: a.position - 1,
                            ans: "Yes",
                          });
                        }
                      }}
                    >
                      <span className="major-ans-text">نعم 👍 </span>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
      <CompleteModal
        timeEnd={timeEnd}
        show={modalShow}
        data={results}
        query={query}
        timeTaken={timeDiff}
        selectedQuestionArr={selectedQuestionArr}
        // history={props.history}
        // onHide={() => setModalShow(false)}
      />
      {/* <a
        className="whatsapp-fixed-icon-con"
        target="_blank"
        href={"https://wa.me/96566687847"}
      >
        <img
          className="whatsapp-fixed-icon-con"
          src="https://storage.googleapis.com/destination_ulearn/courses/whatsappIcon.png"
          alt="icon"
        />
      </a> */}
    </div>
  );
}
