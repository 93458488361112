/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Accordion, Col, Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import FaqComponent from "../Component/FaqComponent/index";
import { PlainLogo } from "../Assets/Images/PlainLogo";
import { PopupButton } from "react-calendly";

import { dataAction, State } from "../state";
import {
  ContactHeaderText,
  ContainerArcks,
  HeaderText,
  ImgContainer,
  ResultCard,
  ResultCardSectionMargin,
  ResultCardText,
  ResultCardTop,
  RowCol,
  SectionMargin,
  Subtext,
  Text,
  ContactSubtext,
  RequiredDocText,
  RequiredDocSubtext,
  PartnerSubtext,
  ResultCardHeaderSection,
  AnimationSection,
  ContactButton,
} from "./styled/result";
import "./styled/result.scss";
import { CircleCheck } from "../Assets/Images/CircleCheck";
import Logo from "../Assets/Images/Logo";
import ChatIcon from "../Assets/Images/ChatIcon";
import { UniversitySlider } from "../Component/UniversitySlider";
import { ButtonComponent } from "../Component/ButtonComponent";
import { SuccessAnimation } from "../Component/SuccessAnimation/SuccessAnimation";

export default function ResultUapply() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let { language } = useParams();
  const [lang, setlang] = React.useState(language);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let utm_source = params.get("utm_source");
  let utm_content = params.get("utm_content");
  let utm_medium = params.get("utm_medium");
  let utm_campaign = params.get("utm_campaign");
  let utm_term = params.get("utm_term");
  const router = useLocation();
  localStorage.setItem("query", router.search);
  const query = localStorage.getItem("query");

  const docRequired = [
    {
      Bachlors: [
        t("common:requiredDocBachlor1"),
        t("common:requiredDocBachlor2"),
        t("common:requiredDocBachlor3"),
      ],
    },
    {
      Masters: [
        t("common:requiredDocMaster1"),
        t("common:requiredDocMaster2"),
        t("common:requiredDocMaster3"),
        t("common:requiredDocMaster4"),
        t("common:requiredDocMaster5"),
        t("common:requiredDocMaster6"),
      ],
    },
    {
      phd: [
        t("common:requiredDocPhd1"),
        t("common:requiredDocPhd2"),
        t("common:requiredDocPhd3"),
        t("common:requiredDocPhd4"),
        t("common:requiredDocPhd5"),
        t("common:requiredDocPhd6"),
        t("common:requiredDocPhd7"),
        t("common:requiredDocPhd8"),
        t("common:requiredDocPhd9"),
      ],
    },
  ];

  const dispatch = useDispatch();
  const { showAnimation } = bindActionCreators(dataAction, dispatch);
  const data = useSelector((state) => state.data);

  React.useEffect(() => {
    window.scroll(0, 0);
    setlang(language);
    i18n.changeLanguage(language);
    document.body.style.overflow = "scroll";
    document.body.style.height = "auto";
    // showAnimation(true);
  }, []);

  // if (!location.state) {
  //   return <Navigate to="/" />;
  // }

  // if (!language) {
  //   return <Navigate to="/ar/results" />;
  // }

  localStorage.setItem("degree", location.state.degree.en_name);

  var degree = localStorage.getItem("degree");
  var qualified = location.state.qualified;

  return (
    <>
      <Navbar collapseOnSelect expand="lg" sticky="top">
        <Container>
          <a
            // className="whatsapp-fixed-icon-con"
            target="_blank"
            href={"https://wa.me/96566687847"}
            rel="noreferrer"
          >
            <img
              className="mobile-view"
              style={{ width: 28, height: 28 }}
              src="https://storage.googleapis.com/destination_ulearn/courses/WhatsApp_Logo_1%201.png"
              alt="icon"
            />
          </a>
          <Navbar.Brand href="#home">
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle className="mobile-view">
            <svg
              className="d-flex justify-content-end "
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.66667" cy="2.66667" r="2.66667" fill="black" />
              <circle cx="2.66667" cy="13.3332" r="2.66667" fill="black" />
              <circle cx="13.3332" cy="13.3332" r="2.66667" fill="black" />
              <circle cx="13.3332" cy="2.66667" r="2.66667" fill="black" />
            </svg>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-center">
            <Nav.Link
              style={{ display: "none" }}
              href={utm_source ? `/apply${query}` : "/apply"}
            >
              apply{" "}
            </Nav.Link>

            <Nav.Link href="https://g.page/Ulearn-study-abroad">
              لوكيشن المكتب
            </Nav.Link>
            <Nav.Link href={utm_source ? `/career${query}` : "/career"}>
              اختبار تحديد التخصص{" "}
            </Nav.Link>
            <div className="mobile-view" style={{ padding: "0 15px " }}>
              <PopupButton
                className="btn-primary calendly-btn"
                url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                utm={{
                  utmCampaign: utm_campaign,
                  utmContent: utm_content,
                  utmMedium: utm_medium,
                  utmSource: utm_source,
                  utmTerm: utm_term,
                }}
                rootElement={document.getElementById("root")}
                text="احجز موعد استشارة هاتفية مجانية"
              />
            </div>
          </Navbar.Collapse>

          <div className=" pc-view" style={{ padding: "0 15px " }}>
            <PopupButton
              className="btn-primary calendly-btn d-flex justify-content-end"
              url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
              utm={{
                utmCampaign: utm_campaign,
                utmContent: utm_content,
                utmMedium: utm_medium,
                utmSource: utm_source,
                utmTerm: utm_term,
              }}
              rootElement={document.getElementById("root")}
              text="احجز موعد استشارة هاتفية مجانية"
            />
          </div>
        </Container>
      </Navbar>
      {data.show ? (
        <AnimationSection>
          <SuccessAnimation
            onComplete={async () => {
              showAnimation(false);
            }}
          />
        </AnimationSection>
      ) : null}
      <Container>
        <ResultCardHeaderSection>
          <HeaderText arabic={i18n.language === "ar"}>
            {t("common:applicationSubmitted")}
          </HeaderText>
          <Subtext
            arabic={i18n.language === "ar"}
            fontSize="16px"
            textAlign="center"
          >
            {t("common:applicationSubmittedSubtext")}
          </Subtext>
        </ResultCardHeaderSection>
        <ResultCardSectionMargin className="d-flex justify-content-center">
          <ResultCard>
            <ResultCardTop className="d-flex justify-content-center align-items-center">
              <svg
                width="58"
                height="15"
                viewBox="0 0 58 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0582 14.7952C15.5364 14.7952 16.0977 14.692 16.6591 14.4855V12.5035C16.368 12.5861 16.0977 12.6273 15.869 12.6273C15.4117 12.6273 15.1414 12.4415 15.1414 11.9873V0.198242H12.6465V12.1525C12.6465 13.9694 13.6652 14.7952 15.0582 14.7952Z"
                  fill="white"
                />
                <path
                  d="M27.269 9.36522C27.269 6.53667 25.7305 4.20363 22.5495 4.20363C19.1399 4.20363 17.6845 6.88766 17.6845 9.55104C17.6845 12.2764 19.1814 14.7952 22.7159 14.7952C24.4415 14.7952 25.7929 14.1965 26.9156 12.9577L25.19 11.4505C24.4831 12.2557 23.6514 12.5448 22.7366 12.5448C21.115 12.5448 20.2833 11.657 20.1794 10.315H27.2067C27.2482 9.98461 27.269 9.67492 27.269 9.36522ZM20.1586 8.35355C20.3041 7.34188 21.011 6.26827 22.5287 6.26827C23.8386 6.26827 24.8365 7.05283 24.8781 8.35355H20.1586Z"
                  fill="white"
                />
                <path
                  d="M33.2891 4.20363C31.4179 4.20363 29.9002 4.92626 28.6735 6.39215L30.4823 7.89933C31.2308 6.90831 32.208 6.3715 33.2475 6.3715C34.4326 6.3715 35.181 7.05283 35.181 8.16773V8.45678H32.6238C31.4595 8.45678 30.5863 8.60131 29.9002 8.911C28.9022 9.36522 28.3617 10.2737 28.3617 11.4712C28.3617 13.4119 29.9002 14.7952 32.0624 14.7952C33.4138 14.7952 34.5781 14.2171 35.181 13.2674L35.3889 14.5887H37.6759V8.16773C37.6759 5.71082 35.9919 4.20363 33.2891 4.20363ZM32.4575 12.6893C31.4595 12.6893 30.8566 12.2351 30.8566 11.5124C30.8566 11.1408 31.0021 10.8518 31.2724 10.6659C31.605 10.4182 32.104 10.315 32.9564 10.315H35.181C35.181 11.6363 33.9544 12.6893 32.4575 12.6893Z"
                  fill="white"
                />
                <path
                  d="M45.5623 4.20363C44.398 4.20363 43.2545 4.88496 42.8179 6.14439V4.4101H40.3022V14.5887H42.7971V9.46846C42.7971 7.54834 43.5456 6.61926 45.0633 6.61926C45.5623 6.61926 45.9573 6.74314 46.3107 6.97025L47.5166 4.78173C46.8513 4.36881 46.2484 4.20363 45.5623 4.20363Z"
                  fill="white"
                />
                <path
                  d="M54.3818 4.20363C53.1343 4.20363 52.0116 4.61656 51.2839 5.58694V4.4101H48.7891V14.5887H51.2839V10.5214C51.2839 8.70454 51.2839 6.3715 53.6125 6.3715C55.5045 6.3715 55.5045 7.94062 55.5045 8.6426V14.5887H57.9994V8.02321C57.9994 5.60759 56.6895 4.20363 54.3818 4.20363Z"
                  fill="white"
                />
                <path
                  d="M8.2379 2.05519L8.28384 2.10081C8.75728 2.57097 9.52488 2.57097 9.99832 2.10082C10.4718 1.63067 10.4718 0.868401 9.99832 0.398249L9.95237 0.352613C9.47893 -0.117539 8.71133 -0.117538 8.23789 0.352615C7.76445 0.822768 7.76446 1.58504 8.2379 2.05519Z"
                  fill="white"
                />
                <path
                  d="M1.23638 0.00291063L2.26599 2.45914H0.204819L1.23638 0.00291063Z"
                  fill="white"
                  stroke="black"
                  stroke-width="0.00225225"
                />
                <path
                  d="M0 4.37402L0.00892996 9.70117C0.00892996 11.1158 0.504424 12.3152 1.49541 13.2993C2.49879 14.2834 3.72514 14.7755 5.17446 14.7755C6.63616 14.7755 7.86251 14.2834 8.8535 13.2993C9.85688 12.3152 10.3586 11.1158 10.3586 9.70117L10.3496 4.37402H7.87836L7.87835 9.70117C7.87835 10.4885 7.63335 11.042 7.12546 11.5833C6.61758 12.1122 5.96725 12.3767 5.17446 12.3767C4.38167 12.3767 3.73133 12.1122 3.22345 11.5833C2.72795 11.042 2.47127 10.5008 2.47127 9.70117L2.47128 4.37402H0Z"
                  fill="white"
                />
              </svg>
            </ResultCardTop>
            <div style={{}}>
              <RequiredDocText arabic={i18n.language === "ar"}>
                {t("common:requiredDoc")}
              </RequiredDocText>
              <RequiredDocSubtext arabic={i18n.language === "ar"}>
                {t("common:toPursue")}{" "}
                <span style={{ fontWeight: 900 }}>
                  {i18n.language === "ar"
                    ? location.state.degree.ar_name
                    : location.state.degree.en_name}
                </span>{" "}
                {t("common:degreeinUK")}
              </RequiredDocSubtext>
            </div>
            <div
              style={{
                borderTop: "1px solid #E8E9F5",
                borderBottom: "1px solid #E8E9F5",
                padding: "14px 0",
                margin: "9px 0  30px 0",
              }}
            >
              {degree === "Bachelor's"
                ? docRequired[0].Bachlors?.map((x) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 11,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : degree === "Master's"
                ? docRequired[1].Masters?.map((x) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 5,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : degree === "PhD"
                ? docRequired[2].phd?.map((x) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 5,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : null}
            </div>
            {qualified ? (
              <div
                className="d-flex justify-content-center"
                style={{ zIndex: 3 }}
              >
                <ButtonComponent
                  label={t("common:sendOnWa")}
                  btnType={"dark"}
                  width="460px"
                  style={{
                    height: 52,
                    zIndex: 99,
                    fontFamily: i18n.language === "ar" ? "Cairo" : "Dm Sans",
                  }}
                  onClick={() => window.open("https://wa.me/96566687847")}
                />
              </div>
            ) : null}
          </ResultCard>
        </ResultCardSectionMargin>
        <SectionMargin>
          <HeaderText arabic={i18n.language === "ar"} marginBottom="86px">
            {t("common:services")}
          </HeaderText>

          <RowCol
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
            direction={"column-reverse"}
          >
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  width="548px"
                  height="470px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54dd%20(1)-min.png")`}
                />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="40px"
                textAlign={i18n.language === "ar" ? "right" : "left"}
                lineHeight={"64px"}
                arabic={i18n.language === "ar"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection1Text1")}</b>
                <br />{" "}
                <b style={{ color: "#299cf7" }}>
                  {t("common:serviceSection1Text2")}
                </b>{" "}
                {t("common:serviceSection1Text3")}
              </Subtext>
              <Subtext
                fontSize="24px"
                textAlign={i18n.language === "ar" ? "right" : "left"}
                lineHeight={"40px"}
                arabic={i18n.language === "ar"}
                className="mobile-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection1Text1")}</b>{" "}
                <b style={{ color: "#299cf7" }}>
                  {t("common:serviceSection1Text2")}
                </b>{" "}
                {t("common:serviceSection1Text3")}
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection1Desc")}
                <br />
                {i18n.language === "ar"
                  ? t("common:serviceSection1Desc2")
                  : null}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
          </RowCol>

          <RowCol
            direction="column"
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
          >
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="40px"
                lineHeight={"64px"}
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection2Text1")}</b>
                <br />{" "}
                <b style={{ color: "#299cf7" }}>
                  {t("common:serviceSection2Text2")}
                </b>
              </Subtext>
              <Subtext
                fontSize="24px"
                lineHeight={"44px"}
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="mobile-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b> {t("common:serviceSection2Text1")}</b>
                <b style={{ color: "#299cf7" }}>
                  {" "}
                  {t("common:serviceSection2Text2")}
                </b>
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection2Desc")}
                <br />
                {t("common:serviceSection2Desc2")}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  width="560px"
                  height="520px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54-min.png")`}
                />
              </div>
            </Col>
          </RowCol>

          <RowCol
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
            direction="column-reverse"
          >
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  last
                  width="548px"
                  height="428px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54%20(1)-min.png")`}
                />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="40px"
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                lineHeight={"64px"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                {t("common:serviceSection3Text1")}
                <br />{" "}
                <b style={{ color: "#299cf7" }}>
                  {" "}
                  {t("common:serviceSection3Text2")}
                </b>
              </Subtext>
              <Subtext
                fontSize="24px"
                lineHeight={"44px"}
                className="mobile-view"
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b style={{ color: "#299cf7" }}>
                  {t("common:serviceSection3Text1")}
                </b>{" "}
                <b>{t("common:serviceSection3Text2")}</b>
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection3Desc")}
                <br />
                {t("common:serviceSection3Desc2")}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
          </RowCol>
        </SectionMargin>
      </Container>
      {qualified ? (
        <>
          <SectionMargin className="container pc-view">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                background: "#299cf7",
                padding: "40px 20px",
                borderRadius: 20,
              }}
            >
              <ChatIcon style={{ marginBottom: 24 }} />
              <ContactHeaderText arabic={i18n.language === "ar"}>
                {t("common:contactSection")}
              </ContactHeaderText>
              <ContactSubtext arabic={i18n.language === "ar"}>
                {t("common:contactSectionDesc")}{" "}
              </ContactSubtext>
              <ContactButton
                btnType={"light"}
                width="335px"
                style={{
                  marginTop: 24,
                  height: 56,
                  border: "none",
                }}
                onClick={() => window.open("https://wa.me/96566687847")}
              >
                {t("common:contactBtn")}
              </ContactButton>
            </div>
          </SectionMargin>
          <SectionMargin className="mobile-view">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                background: "#299cf7",
                padding: "40px 20px",
                borderRadius: 20,
              }}
            >
              <ChatIcon style={{ marginBottom: 24 }} />
              <ContactHeaderText arabic={i18n.language === "ar"}>
                {t("common:contactSection")}
              </ContactHeaderText>
              <ContactSubtext arabic={i18n.language === "ar"}>
                {t("common:contactSectionDesc")}{" "}
              </ContactSubtext>
              <ContactButton
                btnType={"light"}
                width="335px"
                style={{
                  marginTop: 24,
                  height: 56,
                  border: "none",
                }}
                onClick={() => window.open("https://wa.me/96566687847")}
              >
                {t("common:contactBtn")}
              </ContactButton>
            </div>
          </SectionMargin>
        </>
      ) : null}
      <Container>
        <SectionMargin className="d-flex flex-column align-items-center justify-content-center">
          <HeaderText
            width="100%"
            textAlign={i18n.language === "ar" ? "right" : "left"}
            arabic={i18n.language === "ar"}
          >
            {t("common:partnerSection")}{" "}
          </HeaderText>
          <PartnerSubtext arabic={i18n.language === "ar"}>
            {t("common:partnerSectionDesc")}
          </PartnerSubtext>
        </SectionMargin>
      </Container>
      <div className="container pc-view" style={{ marginTop: 50 }}>
        <UniversitySlider arabic={i18n.language === "ar"} />
      </div>
      <div className="mobile-view" style={{ marginTop: 40 }}>
        <UniversitySlider arabic={i18n.language === "ar"} />
      </div>
      <Container>
        {" "}
        <SectionMargin>
          <HeaderText
            textAlign={i18n.language === "ar" ? "right" : "left"}
            arabic={i18n.language === "ar"}
          >
            {t("common:faq")}
          </HeaderText>
          <Accordion>
            <FaqComponent
              answer={t("common:faqAnswer1")}
              question={t("common:faqQuestion1")}
              customPadding={""}
              eventKeyProps={"0"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer2")}
              question={t("common:faqQuestion2")}
              customPadding={""}
              eventKeyProps={"1"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer3")}
              question={t("common:faqQuestion3")}
              customPadding={""}
              eventKeyProps={"2"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer4")}
              question={t("common:faqQuestion4")}
              customPadding={""}
              arabic={i18n.language === "ar"}
              eventKeyProps={"3"}
            />
            <FaqComponent
              answer={t("common:faqAnswer5")}
              question={t("common:faqQuestion5")}
              customPadding={""}
              eventKeyProps={"4"}
              arabic={i18n.language === "ar"}
            />
          </Accordion>
        </SectionMargin>
        <SectionMargin></SectionMargin>
      </Container>
      <ContainerArcks />
    </>
  );
}
