import styled from "styled-components";

export const Text = styled.div(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #676767;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: ${arabic ? "right" : "left"};
`
);

export const Flag = styled.div`
  font-size: 24px;
`;

export const InputComponent = styled.input(
  ({ arabic }) => `
  text-align: ${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  margin-bottom: 15px;
  border-radius: 200px;
  background: rgba(217, 217, 217, 0.30);
  width: 100%;
  border: 0;
  width: 392px;
  height: 50px;
  padding: 0px 20px;

  ::placeholder {
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    direction: ${arabic ? "rtl" : "ltr"};
    color: #99a5b4;
    opacity:0.5
  }
     @media (max-width: 992px) {
    width: 100%;
  }
`
);

export const SubjectsContainer = styled.div`
  width: 392px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const BadgeCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-right: 1rem;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  gap: 4px;
  width: max-content;
  background: #e8e9f5b2;
  border-radius: 61px;
  margin: 5px 5px 0 0;
`;

export const BadgeText = styled.div(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #191d23;
`
);
